export const lightTheme = {
  text: "#363537",
  background: "#f0f0f0",
  panel: "#ffffff",
};
export const darkTheme = {
  text: "#cdd1cc",
  background: "#17181a",
  panel: "#202123",
};
